$plain: #ffffff;

$dark: #111111;

$grey: #2a313b;

$stone: #c6c0b6;
$stone2: #e2d3ba;

$blue1: #6184d8;
