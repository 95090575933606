button {
  background: #333;
  border: solid 1px #444;
  color: $plain;
  padding: 0.5rem 1rem;

  &:hover,
  &:focus {
    opacity: 0.5;
    cursor: pointer;
  }
}
