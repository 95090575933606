.card {
  color: #ffffff;
  border: solid #ffffff 2px;
  padding: 1rem;
  margin: 0.5rem;
  width: 100%;
  height: 160px;
  position: relative;

  @media (min-width: 767px) {
    max-width: 40%;
    margin: 1rem;
  }
}

.card__heading {
  font-weight: bold;
}

.card__desc {
  font-size: 0.8rem;
}

.card__link {
  color: #ffffff;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
