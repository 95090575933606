footer {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;

  p {
    margin: 0.5rem;
  }
}
