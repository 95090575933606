@import "./variables";
@import "./mixins";
@import "./typography";
@import "./buttons";
@import "./icons";
@import "./grid";
@import "./header";
@import "./footer";
@import "./cards";
@import "./panel";
@import "./links";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Modak&display=swap");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  @media (min-width: 1024px) {
    font-size: 1rem;
  }
}

body {
  background-color: $plain;
  color: #333;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

/* IE Styles */

.ie {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

a {
  &:hover {
    opacity: 0.8;
  }
}
