h1,
h2,
h3 {
  font-weight: normal;
}

h1,
h2,
h3 {
  margin: 0;
}

.h-light {
  color: $plain;
}

//Paragraphs
p {
  line-height: 26px;
}

.p-light {
  color: $plain;
}

.p-small {
  font-size: 0.8rem;
}
