/*

  Panel

*/

.panel {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.panel__inner {
  height: 100%;
  background: $blue1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: left;
}

.panel__inner--dark {
  background: $dark;

  h2 {
    color: $plain;
  }
}

.panel__inner--light {
  background: $plain;

  p {
    color: $dark;
  }
}

.panel__inner--stacked {
  flex-direction: column;
}

.panel-restricted {
  @media (min-width: 500px) {
    max-width: 500px;
  }
}

.panel__heading {
  color: $plain;
  font-size: 3rem;
  font-weight: bold;

  @media (min-width: 767px) {
    font-size: 8rem;
  }
}

.panel-graphic-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
}

.panel-graphic-container--stacked {
  flex-direction: column;
  flex-wrap: nowrap;

  @media (min-width: 767px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.panel-icon {
  flex: 0 0 20%;
  text-align: center;
  margin: 1rem;

  p {
    font-size: 12px;
    line-height: 10px;
  }
}

.panel-bold {
  position: relative;
}

.panel-card {
  width: 300px;
  padding: 1rem;
  border: dashed 2px;
  margin: 1rem;

  a {
    color: $dark;
  }
}
