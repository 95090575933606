//Links
.link-dark {
  color: $dark;
}

.link-uppercase {
  text-transform: uppercase;
}

.link-scroll {
  border: solid $plain;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
