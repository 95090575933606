header {
  background: $blue1;
  color: $plain;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
