.icon-container {
  display: flex;
  flex-direction: row;
}

.icon-social {
  display: inline-block;
  margin: 0 0 0 0.5rem;
}

.icon-social svg {
  fill: $plain;
  width: 30px;
  height: 30px;
}
